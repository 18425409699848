body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #1d71b8;
  --secondary: #e53012;
}

.loginPage, .loginPage > div {
  background-image: none !important;
  text-align: center;
}

.loginPage *[class*=RaLogin-card] {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.3)!important;
}

.loginPage {
  max-height: 100vh;
  overflow-y: hidden;
}

.loginPage img {
  padding-top:5em;
}

.loginPage .MuiAvatar-colorDefault, .loginPage button {
  background-color: var(--secondary);
}

p[class*="RaSimpleFormIterator-index-"] {
  visibility: hidden;
}

.collectionTitle > label{
  font-weight: 1000;
}

.MuiAccordion-root, .MuiAccordion-root * {
  display: block !important;
}

/*.customDivider1 {*/
/*  height: .3em;*/
/*}*/

/*.customDivider2 {*/
/*  height: .1em;*/
/*}*/

/**[class*="customDivider"] {*/
/*  border: none;*/
/*  border-radius: 1em;*/
/*  background: var(--secondary);*/
/*  width: 100%;*/
/*}*/

/**[class*="RaSimpleFormIterator-line-"] {*/
/*  border: 0!important;*/
/*}*/

div[class*="ra-input"] div li:first-child {
  margin-top: 2em;
}